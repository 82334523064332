import {gql} from "@apollo/client";

export const sessionStartMutation = gql`
  mutation PlayerSessionStartMutation($lessonId: ID!, $preview: Boolean!, $externalUserId: String, $utm: UTM) {
    sessionStartResult: educationSessionStart(
      lessonId: $lessonId, preview: $preview, externalUserId: $externalUserId, utm: $utm
    ) {
      sessionId
      recognitionEngine
    }
  }
`;


export const sessionSkipMutation = gql`
  mutation PlayerSessionSkipMutation($actionId: ID!, $level: Int!, $sessionId: ID!) {
    ok: educationSessionSkip(actionId: $actionId, level: $level, sessionId: $sessionId)
  }
`;

export const sessionUseHintMutation = gql`
  mutation PlayerSessionUseHintMutation($actionId: ID!, $level: Int!, $sessionId: ID!) {
    ok: educationSessionUseHint(actionId: $actionId, level: $level, sessionId: $sessionId)
  }
`;

export const sessionStartLevelMutation = gql`
  mutation PlayerSessionStartLevelMutation($level: Int!, $sessionId: ID!) {
    ok: educationSessionStartLevel(level: $level, sessionId: $sessionId)
  }
`;


export const sessionPassUserInputMutation = gql`
  mutation PlayerSessionPassMutation(
    $actionId: ID!,
    $level: Int!,
    $sessionId: ID!,
    $audioUploadId: ID,
    $videoUploadId: ID
  ) {
    result: educationSessionPass(
      actionId: $actionId,
      level: $level,
      sessionId: $sessionId,
      audioUploadId: $audioUploadId,
      videoUploadId: $videoUploadId
    ) {
      passed
      branchId
      recognizedText
      scenarioId
      interrupt
      errorReason
    }
  }
`;

export const sessionPassStreamUserInputMutation = gql`
  mutation PlayerSessionPassStreamMutation(
    $uuid: ID!,
    $actionId: ID!,
    $level: Int!,
    $sessionId: ID!,
    $audioUploadId: ID,
    $videoUploadId: ID,
    $recognizedText: String,
    $duration: Float,
    $sttError: Boolean,
  ) {
    result: educationSessionPassStream(
      uuid: $uuid,
      actionId: $actionId,
      level: $level,
      sessionId: $sessionId,
      audioUploadId: $audioUploadId,
      videoUploadId: $videoUploadId,
      recognizedText: $recognizedText,
      duration: $duration,
      sttError: $sttError,
    ) {
      passed
      branchId
      recognizedText
      scenarioId
      interrupt
      errorReason
    }
  }
`;

export const sessionSelectInputMutation = gql`
  mutation PlayerSessionSelectInputMutation(
    $actionId: ID!,
    $level: Int!,
    $sessionId: ID!,
    $index: Int,
  ) {
    result: educationSessionSelectInput(
      actionId: $actionId,
      level: $level,
      sessionId: $sessionId,
      index: $index
    ) {
      passed
      branchId
      scenarioId
      interrupt
      errorReason
    }
  }
`;

export const sessionFinish = gql`
  mutation PlayerSessionFinish($sessionId: ID!) {
    result: educationSessionFinish(sessionId: $sessionId) {
      sessionId
      passed
      score
      time
    }
  }
`;

export const sessionSubmitFeedback = gql`
  mutation PlayerSessionSubmitFeedback(
    $data: DetailedSessionFeedbackInput, $score: Int, $message: String, $contacts: String, $sessionId: ID!
  ) {
    ok: educationSessionSubmitFeedback(
      data: $data, score: $score, message: $message, contacts: $contacts, sessionId: $sessionId
    )
  }
`;

export const sessionResumeMutation = gql`
  mutation PlayerSessionResume($lessonId: ID!, $externalUserId: ID){
    progress: educationSessionResume(lessonId: $lessonId, externalUserId: $externalUserId){
      sessionId
      level
      openedActions
      exposedActions
      lastScenarioId
      lastScenarioAction
      lastMainScenarioAction
    }
  }
`

export const scenarioFinishMutation = gql`
  mutation PlayerSessionFinishScenario(
    $sessionId: ID!,
    $level: Int!,
    $scenarioId: ID!,
    $actionId: ID,
  ) {
    ok: educationSessionFinishScenario(
      sessionId: $sessionId,
      level: $level,
      scenarioId: $scenarioId,
      actionId: $actionId
    )
  }
`;


export const sessionSelectRandomAvatarPhraseMutation = gql`
  mutation PlayerSessionSelectRandomAvatarPhraseMutation(
    $sessionId: ID!,
    $level: Int!,
    $scenarioId: ID!,
    $actionId: ID!,
  ) {
    branchId: educationSessionSelectRandomAvatarPhrase(
      sessionId: $sessionId,
      level: $level,
      scenarioId: $scenarioId,
      actionId: $actionId
    )
  }
`;