import {gql} from "@apollo/client";
import {
  Course,
  EducationSessionResult,
  ErrorAudio,
  Lesson,
  Maybe,
  ResultDescription,
  Scalars,
  SessionSpeechAnalysis,
} from "../../schema";
import SessionActionsList from "../../components/results/SessionActionsList";
import {Scenario} from "../../types";
import Results, {QueryData as ResultsComponentQueryData} from "../../components/player/Results";

export type QueryData = {
  lesson: Lesson,
  scenarios: Scenario[]
}

export type ErrorsAudioQueryData = {
  audios: ErrorAudio[]
}

export const query = gql`
  query PlayerSceneQuery($lessonId: ID!, $externalUserId: ID) {
    lesson: getLesson(lessonId: $lessonId, externalUserId: $externalUserId) {
      id
      accessToken
      name
      accountId
      languageCode
      background
      canResume
      hasPassed
      # canPlay
      avatar {
        id
        name
        gender
        previewFull
        modelType
        gltfAsset
      }
      gestures
      mode
      displayMode
      inputMode
      badWordsFilter
      inviteByLink
      selectableInputs
      useCharacterVoiceForErrors
      muteNonUserSpeech
      showPreviousPhrase
      showDetailedResults
      evaluateScenarios
      evaluateQuizScore
      startMessage
      resultMessage
      timeLimit
    }

    scenarios: getLessonScenarios(lessonId: $lessonId) {
      __typename
      id
      name
      isMain
      triggerPhrases
      afterAction
      actions {
        id
        ... on AvatarAction {
          text
          audio
          emotion
          gesture
          onlyFirstLevel
          media
          mediaType
          mediaViewRequired
          setMediaAsBackground
        }

        ... on SystemAction {
          text
          audio
          onlyFirstLevel
          media
          mediaType
          mediaViewRequired
          setMediaAsBackground
        }

        ... on UserInputAction {
          expectedText
          freeSpeech
          hintText
          freeSpeechMinTime
          freeSpeechMaxTime
        }

        ... on ChoiceUserInputAction {
          id
          branches {
            id
            state
            targetScenarioId
            userInput {
              id
              expectedText
            }

            avatarReaction {
              id
              text
              audio
              emotion
              gesture
              onlyFirstLevel
              media
              mediaType
              mediaViewRequired
              setMediaAsBackground
            }

            systemReaction {
              id
              text
              audio
              onlyFirstLevel
              media
              mediaType
              mediaViewRequired
              setMediaAsBackground
            }
          }
        }

        ... on RandomAvatarAction {
          id
          branches {
            id
            targetScenarioId
            avatarAction {
              id
              text
              audio
              emotion
              gesture
              onlyFirstLevel
              media
              mediaType
              mediaViewRequired
              setMediaAsBackground
            }
          }
        }

        ...SessionActionsList
      }
    }
  }

  ${SessionActionsList.fragments.root}
`;

export type ResultsQueryVars = {
  sessionId: Scalars["ID"],
  externalUserId: Maybe<Scalars["ID"]>,
  lessonId: Maybe<Scalars["ID"]>,
}

export type ResultsQueryData = {
  results: Pick<EducationSessionResult, "actionResults"> & ResultsComponentQueryData["results"]
  resultDescriptions: ResultDescription[]
  streamingSpeechAnalysis: SessionSpeechAnalysis[]
}

export const resultsQuery = gql`
  query PlayerResultsScreenQuery($sessionId: ID!, $externalUserId: ID, $lessonId: ID!) {
    results: getSessionResult(sessionId: $sessionId, externalUserId: $externalUserId) {
      ...Results
      actionResults {
        ...SessionActionsListActionResult
      }
    }
    
    resultDescriptions: getLessonResultDescriptions(lessonId: $lessonId) {
      id
      minScore
      maxScore
      message
    }

    streamingSpeechAnalysis: getStreamingSpeechAnalysis(sessionId: $sessionId , externalUserId: $externalUserId) {
      actionId
      pitchRange
      speechSpeed
    }
  }

  ${SessionActionsList.fragments.actionsResults}
  ${Results.fragments.root}
`

export type NextLessonQueryVars = {
  lessonId: Lesson["id"],
  courseId?: Course["id"]
}

export type NextLessonQueryData = {
  nextLesson: ResultsComponentQueryData["nextLesson"],
}

export const nextLessonQuery = gql`
  query PlayerNextLessonQuery( $lessonId: ID!, $courseId: ID) {
    nextLesson: getNextLesson(lessonId: $lessonId, courseId: $courseId) {
      ...NextLesson
    }
  }

  ${Results.fragments.nextLesson}
`
export const errorsAudioQuery = gql`
  query ErrorsAudioQuery($gender: String!) {
    audios: getErrorsAudio(gender: $gender) {
      errorReason
      filepath
      gesture
      emotion
    }
  }
`
