import React, {ChangeEventHandler, useCallback, useState} from "react";
import {toNumber} from "lodash";
import Textarea from "../../ui/textarea";
import {useFormState} from "../../hooks/useFormState";
import Button from "../../ui/button";
import classes from "../account/AssignModal.module.css";
import {useTranslation} from "react-i18next";
import {
  ParametricResultCondition,
  ParametricResultConditionInput,
  ParametricResultDescription,
  ParametricResultDescriptionInput
} from "../../schema";
import Input from "../../ui/input";
import {AddIcon, DeleteIcon} from "../../ui/icons";
import Select from "../../ui/select";


type contentProps = React.ComponentProps<"div"> & {
  desc?: ParametricResultDescription
  parameters?: string[]
  onSave?: (data: ParametricResultDescriptionInput) => void
  onDelete?: () => void
}

function ParametricResultDescriptionModalHeader() {
  const {t} = useTranslation();
  return <span>{t("components.ParametricResultDescriptionModal.title")}</span>
}

 function ParametricResultDescriptionModalContent({desc, parameters, onSave, onDelete}: contentProps) {
  const {t} = useTranslation();

  const formState = useFormState<{
    conditions: ParametricResultConditionInput[],
    message: string,
  }>({
    initialValues: {
      conditions: desc?.conditions ?? [],
      message: desc?.message ?? "",
    },

    preventDefault: true,

    onSubmit: (data) => {
      data.conditions = [...data.conditions.map(
        condition => ({parameter: condition.parameter, minValue: condition.minValue, maxValue: condition.maxValue})
      )];
      onSave && onSave(data);
    }
  });

  const [conditionsList, setConditionsList] = useState<ParametricResultCondition[]>(desc?.conditions ?? []);

  const onMinChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const index = toNumber(e.currentTarget.getAttribute("data-index")!);
    const value = toNumber(e.currentTarget.value);
    const temp = [...conditionsList];
    temp[index] = {parameter: temp[index].parameter, minValue: value, maxValue: temp[index].maxValue}
    setConditionsList(temp);
    formState.setValues(() => ({
      conditions: [...temp]
    }))
  }, [formState, conditionsList])

  const onMaxChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const index = toNumber(e.currentTarget.getAttribute("data-index")!);
    const value = toNumber(e.currentTarget.value);
    const temp = [...conditionsList];
    temp[index] = {parameter: temp[index].parameter, minValue: temp[index].minValue, maxValue: value}
    setConditionsList(temp);
    formState.setValues(() => ({
      conditions: [...temp]
    }))
  }, [formState, conditionsList])

  const onParameterChange = useCallback<ChangeEventHandler<HTMLSelectElement>>((e) => {
    const index = toNumber(e.currentTarget.getAttribute("data-index")!);
    const value = e.currentTarget.value;
    const temp = [...conditionsList];
    temp[index] = {parameter: value, minValue: temp[index].minValue, maxValue: temp[index].maxValue}
    setConditionsList(temp);
    formState.setValues(() => ({conditions: [...temp]}))
  }, [formState, conditionsList])

  const onAddConditionButtonClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const temp = [...conditionsList, {parameter: "", minValue: 0, maxValue: 0}];
    setConditionsList(temp)
    formState.setValues(() => ({conditions: [...temp]}))
  }, [conditionsList, formState])

  const onDeleteConditionButtonClick = useCallback((e: React.MouseEvent) => {
    const index = toNumber(e.currentTarget.getAttribute("data-index")!);
    e.stopPropagation();
    e.preventDefault();
    const temp = [...conditionsList];
    temp.splice(index, 1);
    setConditionsList(temp)
    formState.setValues(() => ({conditions: [...temp]}))
  }, [conditionsList, formState])

  const submitDisabled = undefined

  return (
    <form className={classes.root} method="post" onSubmit={formState.submitHandler}>
      <>
        <p className={classes.helpText}>{t("components.ParametricResultDescriptionModal.conditionsHelp")}</p>
        {conditionsList.map((condition, index) => (
          <>
            <Select
              data-index={index} value={condition.parameter ?? ""} onChange={onParameterChange}
            >
              <option value={""}>
                {t("components.ParametricResultDescriptionModal.notSelected")}
              </option>
              {parameters?.map(param => (
                <option value={param}>
                  {param}
                </option>
              ))}
            </Select>
            <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
              {t("components.ParametricResultDescriptionModal.from")}
              <Input
                data-index={index}
                style={{width: "70px", height: "40px", margin: "12px 12px 20px 12px"}}
                required
                type='number'
                value={condition.minValue ?? 0}
                onChange={onMinChange}
              />
              {t("components.ParametricResultDescriptionModal.to")}
              <Input
                data-index={index}
                style={{width: "70px", height: "40px", margin: "12px 12px 20px 12px"}}
                required
                type='number'
                value={condition.maxValue ?? 0}
                onChange={onMaxChange}
              />
              <Button data-index={index} size='s' onClick={onDeleteConditionButtonClick}>
                <DeleteIcon fontSize={14}/>
              </Button>
            </div>
          </>
        ))}
        <Button
          style={{marginBottom: "28px"}}
          color="secondary"
          onClick={onAddConditionButtonClick}
        >
          <AddIcon style={{marginRight: "4px"}}/>
          {t("common.add")}
        </Button>

        <p className={classes.helpText}>{t("components.ResultDescriptionModal.messageHelpText")}</p>
        <Textarea
          className={classes.input}
          name="message"
          value={formState.values.message}
          rows={8}
          placeholder={t("components.ResultDescriptionModal.messagePlaceholder")}
          onChange={formState.changeHandler}
        />
      </>

      <div className={classes.bottom}>
        <Button type="submit" color={submitDisabled ? "secondary" : "success"} disabled={submitDisabled}>
          {t("components.ResultDescriptionModal.submit")}
        </Button>
        {desc && (
          <Button type="button" color="danger" onClick={onDelete}>
            {t("components.ResultDescriptionModal.delete")}
          </Button>
        )}
      </div>
    </form>
  )
}

const ParametricResultDescriptionModal = {
  Header: ParametricResultDescriptionModalHeader,
  Content: ParametricResultDescriptionModalContent,
}

export default ParametricResultDescriptionModal;
